import {
  SET_DARK_MODE,
  STORE_RESET_PASSWORD_TOKEN,
  WS_MESSAGE,
} from "constants/app";

export const storeResetPasswordToken = (token) => async (dispatch) =>
  dispatch({
    type: STORE_RESET_PASSWORD_TOKEN,
    token,
  });

export const setDarkMode = (token) => async (dispatch) =>
  dispatch({
    type: SET_DARK_MODE,
    token,
  });

export const websocketMessageReceived = (token) => async (dispatch) =>
  dispatch({
    type: WS_MESSAGE,
    token,
  });
