export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
});
